.adminLeftMenuStyle .adminLeftMenuStyleIcon {
  fill: rgb(131, 131, 131);
}
.adminLeftMenuStyle:hover .adminLeftMenuStyleIcon {
  fill: rgba(233, 96, 17, 1);
}

.adminLeftMenuStyleIconActive .adminLeftMenuStyleIcon{
  fill: rgba(233, 96, 17, 1);
}

.adminLeftMenuStyle:hover {
  color: rgba(233, 96, 17, 1);
}

.adminTableRow:hover .adminTableButtons {
  transition: all;
  transition-duration: 350ms;
  opacity: 1;
}
.adminTableButtons {
  opacity: 0;
}
.adminTableButtonSvg{
  fill: rgb(131,131,131);
}
.adminTableButtonSvg:hover{
  fill: white;
}

.ql-toolbar {
  height: 42px !important;
}