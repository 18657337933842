.section1Imgfon {
  background: url(../images/Subtract\(3\).webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section1fon {
  background: linear-gradient(
    273.91deg,
    rgba(0, 0, 0, 0) 32.3%,
    rgba(0, 0, 0, 0.7) 94.13%
  );
}
/* b122210f7b6db1817845c019234909dc.webp */
.section2Imgfon {
  background: url(../images/b122210f7b6db1817845c019234909dc.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section5Imgfon {
  background: url(../images/Subtract\(4\).webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.section5fon {
  background: linear-gradient(
    272.86deg,
    rgba(0, 0, 0, 0) 31.11%,
    rgba(0, 0, 0, 0.7) 61.76%
  );
}
