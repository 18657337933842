.oneFile .ant-upload,
.oneFile .ant-upload-list-item-container {
  width: 650px !important;
  height: 74px !important;
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 20px !important;
  border: 2px dashed rgba(131, 131, 131, 1);
}

.fotos .ant-upload,
.fotos .ant-upload-list-item-container,
.fotos .ant-upload-list-item,
.fotos .ant-upload-list-item-done {
  width: 64px !important;
  height: 64px !important;
  border-radius: 20px !important;
  padding: 0 !important;
  overflow: hidden;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.fotos .ant-upload-list-item-container::before,
.fotos .ant-upload-list-item::before,
.fotos .ant-upload-list-item-done::before {
  border-radius: 12px;
  background-color: rgba(243, 244, 245, 0.54) !important;
}

.ant-tooltip-content {
  display: none !important;
}

.ant-upload-list-item-error {
  border: none !important;
}
.hiddenAntUploadItem .ant-upload-list-item-error {
  display: none !important;
}

.ant-statistic-title {
  color: rgba(131, 131, 131, 1) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.ant-statistic-content-value {
  color: white;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

@media (max-width: 940px) {
  .text20font600 {
    font-size: 18px;
    line-height: 22px;
  }
}


.statistic-title {
  color: rgba(131, 131, 131, 1) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.statistic-content-value {
  color: white;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.statistic-content-value span{
  color: rgba(233,96,17,1);
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px;
}

@media (max-width: 940px) {
  .statistic-content-value {
    font-size: 18px;
    line-height: 22px;
  }
}
