.text48font600 {
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
}
.text40font600 {
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
}
.text36font600 {
  font-size: 36px;
  font-weight: 600;
  line-height: 43.2px;
}
.text32font600 {
  font-size: 32px;
  font-weight: 600;
  line-height: 35.2px;
}
.text24font600lh32 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32.16px;
}
.text24font400 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}
.text20font600 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.text20font600lh27 {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.text18font600 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
.text18font400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.text18font400lh24 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.text18font500 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.text16font600 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.text16font500 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.text16font400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.text14font500lh20px {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.text14font600lh20 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.text14font500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.text14font400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.text12font500 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
}
.text12font400 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.limitText1 {
  overflow: hidden;
  display: -webkit-box;
  /* line-height: 24px;
  font-size: 16px; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.limitText2 {
  overflow: hidden;
  display: -webkit-box;
  line-height: 24px;
  font-size: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.limitText3 {
  overflow: hidden;
  display: -webkit-box;
  line-height: 24px;
  font-size: 16px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.limitText4 {
  overflow: hidden;
  display: -webkit-box;
  line-height: 24px;
  font-size: 16px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media (max-width: 940px) {
  .text48font600 {
    font-size: 24px;
    line-height: 28px;
  }
  .text40font600 {
    font-size: 24px;
    line-height: 28px;
  }
  .text24font600lh32 {
    font-size: 20px;
    line-height: 28.6px;
  }
  .text24font400 {
    font-size: 16px;
    line-height: 20px;
  }
  .text20font600 {
    font-size: 18px;
    line-height: 22px;
  }
  .text18font500 {
    font-size: 14px;
    line-height: 18px;
  }
  .text18font400 {
    font-size: 14px;
    line-height: 18px;
  }
  .text16font600 {
    font-size: 14px;
    line-height: 18px;
  }
  .text16font400 {
    font-size: 14px;
    line-height: 18px;
  }
}

.linkStyle {
  line-height: 16px;
  transition: all;
  transition-duration: 100ms;
  font-family: "Gilroy", sans-serif;
  cursor: pointer;
}
.linkStyle:hover {
  color: rgba(233, 96, 17, 1);
}
.linkStyle:active {
  color: rgba(233, 96, 17, 1);
  font-weight: 600;
}

.linFooterkStyle {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  transition: all;
  transition-duration: 100ms;
  cursor: pointer;
}
.linFooterkStyle:hover {
  color: rgba(233, 96, 17, 1);
}
.linFooterkStyle:active {
  color: rgba(233, 96, 17, 1);
  font-weight: 600;
}
.cursor_default{
  cursor: default !important;
}
