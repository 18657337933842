.footerButton {
  color: white;
  height: 44px;
  width: 100%;
  background-color: rgba(233, 96, 17, 1);
  border-radius: 22px;
}

.footerButton:hover {
  background: linear-gradient(0deg, #e96011, #e96011),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.footerButton:active {
  background: linear-gradient(0deg, #e96011, #e96011),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

.labelStyle {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  text-align: left !important;
  padding: 6px 12px 0px 12px;
  color: rgba(79, 92, 101, 1) !important;
}

.inputStyle,
.inputStyle2 {
  width: 100% !important;
  background: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 12px !important;
  transition: all !important;
  transition-duration: 100ms !important;
  outline: none;
  border-radius: 12px !important;
}
.inputStyle .r12stul0 {
  color: white !important;
}
.inputStyle2 .r12stul0 {
  color: rgb(17, 17, 17) !important;
}

.inputStyle .r12stul0::placeholder,
.inputStyle2 .r12stul0::placeholder {
  color: rgba(79, 92, 101, 1);
  opacity: 1; /* Firefox */
}
.inputStyle .f19n0e5 {
  color: white !important;
}
.inputStyle2 .f19n0e5 {
  color: rgb(17, 17, 17) !important;
}

.inputStyle .f19n0e5::placeholder,
.inputStyle2 .f19n0e5::placeholder {
  color: rgba(79, 92, 101, 1);
  opacity: 1;
}

.inputStyle::after,
.inputStyle2::after,
.inputStyle3::after,
.selectStyle::after,
.fui-Select::after,
.___cse1h10_92gkov0::after,
.f122n59::after,
.f1ewtqcl::after,
.f22iagw::after,
.flvyvdh::after,
.fk6fouc::after,
.f10pi13n::after,
.f7ll8cq::after,
.fe5s0lv::after,
.f1gw3sf2::after,
.f13zj6fq::after,
.fhwjr99::after,
.f1mdlcz9::after,
.fo72kxq::after,
.f1n6gb5g::after,
.f15yvnhg::after,
.f1yk9hq::after,
.fhwpy7i::after,
.f14ee0xe::after,
.f1xhbsuh::after,
.fv8e3ye::after,
.ftb5wc6::after,
.fjw5xc1::after,
.f1xdyd5c::after,
.fatpbeo::after,
.fb7uyps::after,
.f1cmft4k::after,
.f1x58t8o::after,
.selectStyle .ant-select,
.selectStyle .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(233, 96, 17, 1) !important;
  border-radius: 12px !important;
  background-image: none !important;
}
.inputStyle3,
.inputStyle3::after,
.selectStyle .ant-select,
.selectStyle .ant-select-selector {
  border: 1px solid gray !important;
  border-radius: 8px !important;
}

.selectStyle,
.fui-Select,
.___cse1h10_92gkov0,
.f122n59,
.f1ewtqcl,
.f22iagw,
.flvyvdh,
.fk6fouc,
.f10pi13n,
.f7ll8cq,
.fe5s0lv,
.f1gw3sf2,
.f13zj6fq,
.fhwjr99,
.f1mdlcz9,
.fo72kxq,
.f1n6gb5g,
.f15yvnhg,
.f1yk9hq,
.fhwpy7i,
.f14ee0xe,
.f1xhbsuh,
.fv8e3ye,
.ftb5wc6,
.fjw5xc1,
.f1xdyd5c,
.fatpbeo,
.fb7uyps,
.f1cmft4k,
.f1x58t8o,
.selectStyle .ant-select,
.selectStyle .ant-select-selector,
.selectStyle::after,
.fui-Select::after,
.___cse1h10_92gkov0::after,
.f122n59::after,
.f1ewtqcl::after,
.f22iagw::after,
.flvyvdh::after,
.fk6fouc::after,
.f10pi13n::after,
.f7ll8cq::after,
.fe5s0lv::after,
.f1gw3sf2::after,
.f13zj6fq::after,
.fhwjr99::after,
.f1mdlcz9::after,
.fo72kxq::after,
.f1n6gb5g::after,
.f15yvnhg::after,
.f1yk9hq::after,
.fhwpy7i::after,
.f14ee0xe::after,
.f1xhbsuh::after,
.fv8e3ye::after,
.ftb5wc6::after,
.fjw5xc1::after,
.f1xdyd5c::after,
.fatpbeo::after,
.fb7uyps::after,
.f1cmft4k::after,
.f1x58t8o::after,
.selectStyle::after .ant-select,
.selectStyle::after .ant-select-selector {
  border-radius: 8px !important;
  background-image: none !important;
  border: none !important;
  box-shadow: none !important;
}

.inputStyle:hover,
.inputStyle2:hover,
.inputStyle3:hover,
/* .selectStyle:hover, */
.fui-Select:hover,
.___cse1h10_92gkov0:hover,
.f122n59:hover,
.f1ewtqcl:hover,
.f22iagw:hover,
.flvyvdh:hover,
/* .fk6fouc:hover, */
.f10pi13n:hover,
.f7ll8cq:hover,
.fe5s0lv:hover,
.f1gw3sf2:hover,
.f13zj6fq:hover,
.fhwjr99:hover,
.f1mdlcz9:hover,
.fo72kxq:hover,
.f1n6gb5g:hover,
.f15yvnhg:hover,
.f1yk9hq:hover,
.fhwpy7i:hover,
.f14ee0xe:hover,
.f1xhbsuh:hover,
.fv8e3ye:hover,
.ftb5wc6:hover,
.fjw5xc1:hover,
.f1xdyd5c:hover,
.fatpbeo:hover,
.fb7uyps:hover,
.f1cmft4k:hover,
.f1x58t8o:hover {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(233, 96, 17, 1) !important;
  background-image: none !important;
}

.selectStyle:hover .ant-select,
.selectStyle:hover .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  background-image: none !important;
}

.selectStyle:hover {
  border: 1px solid gray !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(233, 96, 17, 1) !important;
  background-image: none !important;
}
