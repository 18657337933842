body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Gilroy";
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0px auto;
}

@media (max-width: 1220px) {
  .container {
    width: calc(100% - 40px);
    margin: 0px 20px;
  }
}

.div:hover .divChild {
  display: grid;
}
.divChild {
  display: none;
}
.cardShadow {
  transition: all;
  transition-duration: 200ms;
  cursor: pointer;
}
.cardShadow:hover {
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.12);
}
