.style_editor h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  line-height: 1em;
}
.style_editor li {
  display: list-item;
}
.style_editor ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.style_editor ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.style_editor u {
  text-decoration: underline;
}
.style_editor tt {
  font-family: "Lucida Console", monospace;
}
.style_editor i {
  font-style: italic;
}
.style_editor p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}
@media (max-width: 939.5px) {
  .style_editor h1 {
    font-size: 1.5em;
  }
  .style_editor h2 {
    font-size: 1.3em;
  }
  .style_editor h3 {
    font-size: 0.97em;
  }
  .style_editor h4 {
  }
  .style_editor h5 {
    font-size: 0.63em;
  }
  .style_editor h6 {
    font-size: 0.47em;
  }
  .style_editor p {
    font-size: 14px;
    line-height: 21px;
  }
}
